import React, { useContext } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Heading from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import OnboardingTemplate from 'components/OnboardingTemplate';
import arrowImg from '.../../../src/assets/images/half-purple-arrow.png';
import { NavLink } from 'react-router-dom';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';
import { StyledForm } from './Login.styles';

function Login() {
  const [form] = useForm();
  const { onLogin, user_loading } = useContext(AuthContext);
  return (
    <>
      <OnboardingTemplate>
        <Heading style={{ fontFamily: '"Antonio", sans-serif' }} level={1} color="var(--white)">
          Sign In
        </Heading>
        <StyledForm form={form} onSubmit={onLogin}>
          <Form.Item
            type="text"
            label="Email Address"
            name="email"
            placeholder="Your Email or Username"
            rules={[{ required: true }, { pattern: /^.{0,256}$/, message: 'Maximum Character Length is 256' }]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="password"
            label="Password"
            name="password"
            placeholder="Your Password"
            rules={[
              {
                required: true,
              },
              { pattern: /^.{8,64}$/, message: 'Minimum Character Length is 8 and Maximum Character Length is 64' },
            ]}>
            <Field />
          </Form.Item>
          <div className="flex-wrap">
            <Form.Item label="Remember Me" type="checkbox" rounded name="rememberMe">
              <Field />
            </Form.Item>
            <NavLink to="/">
              <span className="forgot-password">Forgot Password?</span>
            </NavLink>
          </div>
          <div className="btn-holder">
            <Button
              loading={user_loading}
              style={{ fontFamily: '"Antonio", sans-serif' }}
              btntype="new"
              type="white"
              rounded
              htmlType="submit"
              width={150}>
              Sign In
              <img src={arrowImg} alt="arrow" />
            </Button>
          </div>
          <div className="text-holder">
            <p>2023 - 2024 Wiz POS (Point of Sale System) Designed & Develop by Webevis Technologies.</p>
          </div>
        </StyledForm>
      </OnboardingTemplate>
    </>
  );
}

export default Login;
