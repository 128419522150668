/* eslint-disable no-restricted-imports */
import styled from 'styled-components';

export const StyledTopBar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 40px;
  background: var(--white);
  box-shadow: 0px 2px 20px 0px rgba(18, 32, 59, 0.09);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  @media only screen and (max-width: 800px) {
    padding: 12px 10px 12px 30px;
    .actions {
      gap: 0px !important;
    }
    .notification-text {
      display: none;
    }
  }
  .logoWrapper {
    max-width: 80px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .textField {
    display: flex;
    max-width: 561px;
    width: 100%;
    padding: 0 16px;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 1px solid var(--gray-2);
    background: var(--white);
    input {
      height: 40px;
      width: 100%;
      border: none;
      outline: none;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 461px;
    }
    @media only screen and (max-width: 850px) {
      max-width: 361px;
    }
    @media only screen and (max-width: 650px) {
      max-width: 300px;
    }
  }
  .notification {
    display: flex;
    padding: 8px 14px;
    align-items: center;
    gap: 4px;
    border-radius: 60px;
    background: rgba(17, 16, 18, 0.1);
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;
    &:hover {
      .notificationWrapper {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .notificationWrapper {
    max-width: 432px;
    position: absolute;
    top: 0;
    right: 30px;
    padding-top: 64px;
    visibility: hidden;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.4s;
  }
`;
