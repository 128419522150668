import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import CreateProfile from 'pages/create-profile';
import ThankYou from './pages/thank-you';
import PublicRoute from './helpers/publicRoute';
import PrivateRoute from './helpers/privateRoute';
import { AuthContext } from './context/authContext';
import Login from './pages/login';
import Routes from './pages';

function Router() {
  const { isLoggedIn, allowedPages } = useContext(AuthContext);
  return (
    <Switch>
      <PrivateRoute path="/admin/:view/:child?" component={Routes} isLoggedIn={isLoggedIn} />

      <PublicRoute path="/create-profile" component={CreateProfile} isLoggedIn={false} />
      <PublicRoute path="/thank-you" component={ThankYou} isLoggedIn={false} />

      <PublicRoute
        restricted
        path="*"
        component={Login}
        isLoggedIn={isLoggedIn}
        redirectTo={allowedPages.length > 0 ? allowedPages[0] : '/dashboard'}
      />
    </Switch>
  );
}

export default Router;
