/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useState, createContext, useEffect } from 'react';
import { clearCookie, getCookie, setCookie } from 'helpers/common';
import Toast from 'components/molecules/Toast';
import adminService from 'services/adminService';
import { useCancellablePromise } from 'helpers/promiseHandler';
import jwtDecode from 'jwt-decode';

const context = {};

export const AuthContext = createContext(context);
export const AuthContextProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE));
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch_user, setFetchUser] = useState(false);
  const { cancellablePromise } = useCancellablePromise();
  const [reFetch, setRefetch] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [allowedPages, setAllowedPages] = useState(
    JSON.parse(getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE)) || [],
  );
  const [formData, setFormData] = useState({});
  const [businessToken, setBusinessToken] = useState(getCookie(process.env.REACT_APP_BUSINESS_TOKEN_COOKIE));

  const updateFormData = newData => {
    setFormData(prevData => ({ ...prevData, ...newData }));
  };

  const clearFormData = () => {
    setFormData({});
  };

  const onLogout = async () => {
    try {
      if (isLoggedIn) setLoadingUser(true);
      const res = await adminService.removeAdminJwt();
      if (res) {
        clearCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE);
        clearCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      }
    } catch (ex) {
      clearCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE);
      clearCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      Toast({ type: 'error', message: ex?.message });
    } finally {
      setLoadingUser(false);
      setIsLoggedIn(false);
    }
  };

  const getPermissions = () => {
    setLoadingUser(true);
    cancellablePromise(adminService.getCurrentAdmin())
      .then(res => {
        setAllowedPages([...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])]);
        setCookie(
          process.env.REACT_APP_ALLOWED_PAGES_COOKIE,
          JSON.stringify(res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])),
        );
        setLoadingUser(false);
        setUser(res);
      })
      .catch(err => {
        setAllowedPages(['no-permissions']);
        setCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE, JSON.stringify(['no-permissions']));
        setLoadingUser(false);
        Toast({
          type: 'error',
          message: err.message,
        });
      });
  };
  /**
   * @description - This function is used to fetch the user details from the server
   */
  useEffect(() => {
    if (isLoggedIn) {
      getPermissions();
    }
    // listen to event
    window.addEventListener('FETCH_ADMIN_ROLE', () => {
      getPermissions();
    });
    return () => {
      window.removeEventListener('FETCH_ADMIN_ROLE', () => {
        getPermissions();
      });
    };
  }, [isLoggedIn, fetch_user]);

  const onLogin = async ({ email, password }) => {
    setLoadingUser(true);
    try {
      const res = await adminService.login({
        email,
        password,
      });

      if (!res?.token) {
        throw new Error(res?.message);
      }
      setCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE, res?.token);
      setIsLoggedIn(true);
      setLoadingUser(false);
    } catch ({ message }) {
      setIsLoggedIn(false);
      setLoadingUser(false);
      Toast({ type: 'error', message });
    }
  };

  /**
   * @description - If someone tries to temper with the cookies we take the appropriate action
   */
  useEffect(() => {
    function listenCookieChange(callback, interval) {
      let old_admin_token = getCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE);
      let old_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      setInterval(() => {
        const new_bap_token = getCookie(process.env.REACT_APP_ADMIN_TOKEN_COOKIE);
        const new_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
        if (new_bap_token !== old_admin_token) {
          try {
            callback(new_bap_token, process.env.REACT_APP_ADMIN_TOKEN_COOKIE);
          } finally {
            old_admin_token = new_bap_token;
          }
        }
        if (new_allowed !== old_allowed) {
          try {
            callback(new_allowed, process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
          } finally {
            old_allowed = new_allowed;
          }
        }
      }, interval);
    }
    listenCookieChange((value, cookie) => {
      if (cookie === process.env.REACT_APP_ADMIN_TOKEN_COOKIE) {
        if (!value) {
          onLogout();
        }
      }
      if (cookie === process.env.REACT_APP_ALLOWED_PAGES_COOKIE) {
        if (JSON.stringify(allowedPages) !== value && isLoggedIn) {
          getPermissions();
        }
      }
    }, 1000);
  }, []);

  const hasPermission = perm => user?.permissions?.includes(perm);
  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        refetch: () => setRefetch(_ => !_),
        fetchUser: () => setFetchUser(() => !fetch_user),
        setShowTokenModal,
        setLoading,
        hasPermission,
        // env_setting,
        allowedPages,
        showTokenModal,
        loading,
        isLoggedIn,
        fetch: reFetch,
        user,
        loading_user,
        formData,
        updateFormData,
        clearFormData,
        businessToken: businessToken ? jwtDecode(businessToken) : null,
        setBusinessToken,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
