import React from 'react';
import UserActions from 'components/organisms/UserActions';
// import Notifications from 'components/Notifications';
import { StyledTopBar } from './TopBar.styles';
import logo from '../assets/images/wiz-logo.svg';
// import search from '../assets/images/search.svg';
// import bell from '../assets/images/bell.svg';

const TopBar = () => (
  <StyledTopBar>
    <div className="logoWrapper">
      <img src={logo} alt="logo" />
    </div>
    {/* <div className="textField">
      <img src={search} alt="search" />
      <input type="text" placeholder="Type to search..." />
    </div> */}
    <div className="actions" style={{ display: 'Flex', gap: '20px' }}>
      {/* <div className="notification">
        <img src={bell} alt="bell" />
        <div className="notification-text">Notifications</div>
        <div className="notificationWrapper">
          <Notifications />
        </div>
      </div> */}
      <UserActions />
    </div>
  </StyledTopBar>
);

export default TopBar;
