import React, { useEffect, useState } from 'react';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Button from 'components/atoms/Button';
import { useLocation } from 'react-router-dom';
import userService from 'services/userService';
import Toast from 'components/molecules/Toast';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const CreateProfile = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get('token');
  const history = useHistory();
  const onSubmit = async values => {
    setLoading(true);
    try {
      const payload = { ...values };
      delete payload.confirm_password;

      const response = await userService.register(payload);

      if (response?.success) {
        Toast({
          type: 'success',
          message: response?.message,
        });
        history.push('/thank-you');
      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (param && param !== '') {
      const token = jwtDecode(param);
      if (token) form.setFieldsValue({ email: token?.email });
    }
  }, [param]);

  return (
    <div className="profile-page">
      <div className="profile-form-holder">
        <Form form={form} onSubmit={onSubmit}>
          <div className="input-holder">
            <Form.Item
              label="User Name"
              rounded
              name="username"
              placeholder="Enter Username"
              rules={[
                {
                  required: false,
                  message: 'Username is Required',
                },
                { pattern: /^.{8,64}$/, message: 'Minimum Character Length is 8 and Maximum Character Length is 64' },
              ]}>
              <Field />
            </Form.Item>
            <Form.Item
              disabled
              type="email"
              label="Email"
              name="email"
              placeholder="Email"
              rules={[
                { required: true, message: 'Please enter email address' },
                { email: true, message: 'Please enter a valid email' },
                { max: 40, message: 'Email should be at max 40 characters!' },
              ]}>
              <Field />
            </Form.Item>
            <Form.Item
              type="password"
              label="Password"
              name="password"
              placeholder="Password"
              rules={[
                {
                  required: true,
                },
                { password: true },
                { pattern: /^.{8,64}$/, message: 'Minimum Character Length is 8 and Maximum Character Length is 64' },
              ]}>
              <Field />
            </Form.Item>
            <Form.Item
              type="password"
              label="Confirm Password"
              name="confirm_password"
              placeholder="Confirm Password"
              rules={[
                {
                  required: true,
                },
                {
                  password: true,
                },
                {
                  transform: value => value !== form.getFieldValue('password'),
                  message: 'The two passwords that you entered do not match!',
                },
              ]}>
              <Field />
            </Form.Item>
          </div>
          <Button loading={loading} type="primary" htmlType="submit" rounded md>
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CreateProfile;
