import styled, { css } from 'styled-components/macro';

export const StyledLabel = styled.label`
  font-size: var(--font-size-xl);
  line-height: 1;
  color: var(--dark);
  margin-bottom: 0.625rem;
  display: block;
  pointer-events: ${({ $onlyRead }) => $onlyRead && 'none'};
  ${({ labelIcon }) =>
    labelIcon &&
    css`
      display: flex;
      align-items: center;
    `}
  ${({ $labelReverse }) =>
    $labelReverse &&
    css`
      position: relative;
      .label {
        flex-direction: row-reverse;
      }
    `};
  .label {
    display: flex;
    align-items: center;
  }
  .clear-span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
`;

export const RequiredAsterisk = styled.span`
  color: var(--danger-light);
  margin-right: 3px;
`;
