import React, { useContext, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { LoadingContext } from 'context/loadingContext';
import { SideNavContext } from 'context/sideNavContext';
import { AuthContext } from 'context/authContext';
import Avatar from 'assets/images/avatar-img01.png';
import { IoIosArrowDown, IoMdSettings } from 'react-icons/io';
import Link from 'components/atoms/Link';
import { ProfileDropDown } from './UserActions.styles';

function UserActions() {
  const { toggleSideNav } = useContext(SideNavContext);
  const { onLogout, user, allowedPages } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      {isLoading ? (
        <Skeleton circle height={40} width={40} />
      ) : (
        <ProfileDropDown $dropdownOpen={isOpen}>
          <button type="button" className="user-img" onClick={() => setIsOpen(!isOpen)}>
            <div className="image-wrap">
              <img src={Avatar} width={40} height={40} alt="user img" />
            </div>
            <IoIosArrowDown className="icon" />
          </button>
          <div className="dropDown">
            <div className="wrap">
              <div className="img-box">
                <img src={Avatar} width={95} height={95} alt="user img" />
              </div>
              <div className="text-box">
                <span className="email">{user?.email}</span>
              </div>
            </div>
            {allowedPages?.includes('settings') && (
              <ul className="list">
                <li>
                  <Link to="/admin/settings">
                    <IoMdSettings size="20" />
                    Account Settings
                  </Link>
                </li>
              </ul>
            )}
            <button
              type="button"
              className="btn-logout"
              onClick={() => {
                toggleSideNav();
                onLogout();
              }}>
              <span className="material-icons-outlined">logout</span>
              <span>logout</span>
            </button>
          </div>
        </ProfileDropDown>
      )}
    </>
  );
}

export default UserActions;
