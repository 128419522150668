import styled from 'styled-components/macro';
import Link from 'components/atoms/Link';
import Form from 'components/molecules/Form';

export const SubTitle = styled.span`
  color: var(--light-gray);
  margin-bottom: 40px;
  display: block;
  @media (min-width: 768px) {
    margin-bottom: 80px;
  }
`;

export const LoginHead = styled.div`
  display: flex;
  display: none;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px;
  @media (min-width: 768px) {
    padding: 40px 80px;
  }
  h1 {
    margin-bottom: 40px;
  }
`;

export const StyledForm = styled(Form)`
  text-align: left;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  font-family: 'Antonio', sans-serif;
  label {
    color: var(--white);
  }
  .flex-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;

    .forgot-password {
      color: white;
    }
  }
  .text-holder {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;
    text-align: center;
    color: var(--white);
    font-weight: 300;
    margin-bottom: 10px;
  }
  .btn-holder {
    color: #8a5e90;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--light-gray);
  &:hover {
    i {
      transform: translateX(-5px);
    }
  }
  i {
    color: var(--primary);
    transition: transform 0.3s linear;
  }
`;
