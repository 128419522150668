import styled from 'styled-components/macro';

export const ProfileDropDown = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 62px;
  height: 40px;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.4s ease-in-out;

  .user-img {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-wrap {
      flex-shrink: 0;
      width: 29px;
      height: 29px;
      border-radius: 50px;
      overflow: hidden;
    }

    .icon {
      flex-shrink: 0;
      transform: scale(${({ $dropdownOpen }) => ($dropdownOpen ? '-1' : '1')});
      transition: 0.4s;
    }
  }
  .dropDown {
    visibility: ${({ $dropdownOpen }) => ($dropdownOpen ? 'visible' : 'hidden')};
    opacity: ${({ $dropdownOpen }) => ($dropdownOpen ? '1' : '0')};
    transform: translateY(${({ $dropdownOpen }) => ($dropdownOpen ? '0' : '20px')});
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 90%;
    right: 20px;
    width: 215px;
    padding: 15px;
    border-radius: 10px;
    background: var(--white);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (min-width: 576px) {
      width: 295px;
      padding: 20px;
      border-radius: 20px;
    }
    .btn-logout {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--danger);
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 10px;
  }

  .img-box {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-box {
    flex-grow: 1;
    padding-top: 3px;
  }

  .title {
    display: block;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0 0 3px;
  }

  .email {
    display: block;
  }

  button {
    text-transform: capitalize;
    font-weight: 400;
  }

  .list {
    list-style: none;
    margin: 0 0 10px;
    padding: 8px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: #c2c9d1;
    @media (min-width: 576px) {
      margin: 0 0 16px;
    }

    li {
      padding: 5px 0;
      @media (min-width: 576px) {
        padding: 8px 0;
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      color: var(--body-text);

      &:hover {
        color: var(--primary);
      }
    }
  }

  .btn-logout {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      color: var(--danger-light);
    }
  }
`;
