import React from 'react';
import Heading from 'components/atoms/Heading';
import logo from '../../assets/images/logo.png';
import Img01 from '../../assets/images/Img01.png';
import shapeImg1 from '../../assets/images/blur-shape.png';

import { OnboardingBlock, FormCol, BannerCol } from './OnboardingTemplate.styles';

function OnboardingTemplate({ children }) {
  return (
    <OnboardingBlock>
      <img className="img" src={shapeImg1} alt="" />

      <BannerCol>
        <div className="brand_logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <Heading className="heading" level={2} color="var(--white)">
          Break Free From Financial Hassle. Experience Seamless Control with WIZ Banking App!
        </Heading>
        <div className="img-box">
          <img src={Img01} alt="" />
        </div>
      </BannerCol>
      <FormCol>
        <div className="middle-content">{children}</div>
      </FormCol>
    </OnboardingBlock>
  );
}

export default OnboardingTemplate;
