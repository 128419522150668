import styled from 'styled-components/macro';

export const Container = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
  img {
    width: 40%;
  }
  h1 {
    color: var(--primary);
  }
`;
