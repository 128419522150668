import * as React from 'react';
import { StyledButton } from './Button.styles';

function Button({
  children,
  loading,
  htmlType,
  type,
  width,
  disabled,
  color,
  rounded,
  className,
  loader,
  style,
  unStyled,
  ...rest
}) {
  return (
    <StyledButton
      type={htmlType === 'submit' ? 'submit' : 'button'}
      btntype={type}
      width={width}
      rounded={rounded?.toString()}
      color={color}
      className={className}
      unStyled={unStyled}
      disabled={disabled || loading}
      style={style}
      {...rest}>
      {unStyled && loader ? null : children}
      {loader && <span className="loader" />}
    </StyledButton>
  );
}

export default Button;
