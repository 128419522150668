import styled from 'styled-components/macro';

export const OnboardingBlock = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background: #000000;

  .img {
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;
  }
  .middle-content {
    width: 100%;
    text-align: center;
  }
`;
export const FormCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  padding: 20px;
  background: rgba(49, 49, 49, 0.3);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(20px);
`;

export const BannerCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px 20px 0;
  z-index: 1;

  .brand_logo {
    max-width: 120px;
    align-self: flex-start;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .img-box {
    max-width: 450px;
    align-self: center;
    margin-bottom: -100px;

    @media (min-height: 850px) {
      max-width: 690px;
      margin-bottom: 0px;
    }
  }

  .heading {
    font-family: 'Antonio', sans-serif;
    line-height: 45px;
    font-size: 25px;
    max-width: 500px;

    @media (min-width: 992px) {
      line-height: 40px;
      font-size: 30px;
    }

    @media (min-height: 800px) {
      line-height: 59px;
      font-size: 38px;
    }
  }

  .bgShape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;
