import React from 'react';
import ThankyouImg from 'assets/images/Thankyou.png';
import { Container } from './Styles.page';
import Button from '../../atoms/Button';

export default function index() {
  return (
    <Container>
      <img src={ThankyouImg} alt="" />
      <h1>For using Wiz admin portal</h1>
      <Button type="purple" rounded onClick={() => window.close()}>
        Continue
      </Button>
    </Container>
  );
}
